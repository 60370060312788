<template>
  <section id="single-post">
    <div
      v-if="post[0]"
      class="defaultcontent-grid"
    >
      <div class="singlepost-grid-primary">
        <div id="post-content">
          <div id="post-title">
            <p
              v-if="$i18n.locale === 'nb' || post[0].title_en === '' || post[0].title_en === null"
              class="title-default"
            >
              {{ post[0].title_no }}
            </p>
            <p
              v-else
              class="title-default"
            >
              {{ post[0].title_en }}
            </p>
          </div>
          <div id="post-text">
            <MarkedParser
              v-if="$i18n.locale === 'nb'"
              :marked-content="post[0].content_no"
              class="markdown"
            />
            <MarkedParser
              v-else
              :marked-content="post[0].content_en"
              class="markdown"
            />
          </div>
        </div>
        <div
          id="post-meta"
          class="metatext"
        >
          <!-- <div id="post-category">
                        <p v-if="$i18n.locale === 'en'">{{ post[0].news_category.category_en }}</p>
                        <p v-else>{{ post[0].news_category.category_no }}</p>
                    </div> -->
          <div id="post-published">
            <p>{{ post[0].manual_post_date | formatDateFull }}</p>
          </div>
          <ul
            v-if="post[0].rel_exh.length > 0"
            id="post-related"
          >
            <p class="boldtext">
              <span>{{ $tc('posts.relatedExhibition', post[0].rel_exh.length) }}</span>
            </p>
            <li
              v-for="item in post[0].rel_exh"
              :key="item.id"
            >
              <router-link :to="/exhibitions/ + item.slug">
                <span v-if="item.title_main_artist">{{ item.title_main_artist }}: </span> <span>{{ item.title }}</span> <span v-if="item.subtitle">{{ item.subtitle }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
            
      <div class="singlepost-grid-secondary">
        <div
          v-if="post[0].photo_cover"
          id="post-photocover"
        >
          <figure v-if="post[0].photo_cover.formats.medium">
            <img
              :data-src="api_url + post[0].photo_cover.formats.medium.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ post[0].photo_cover.caption }}</figcaption>
          </figure>

          <figure v-else-if="post[0].photo_cover.formats.small">
            <img
              :data-src="api_url + post[0].photo_cover.formats.small.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ post[0].photo_cover.caption }}</figcaption>
          </figure>

          <figure v-else-if="post[0].photo_cover.formats.thumbnail">
            <img
              :data-src="api_url + post[0].photo_cover.formats.thumbnail.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ post[0].photo_cover.caption }}</figcaption>
          </figure>
        </div>

        <div
          v-for="image in post[0].photo_gallery"
          id="post-gallery"
          :key="image.name"
        >
          <figure v-if="image.formats.medium">
            <img
              :data-src="api_url + image.formats.medium.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ image.caption }}</figcaption>
          </figure>

          <figure v-else-if="image.formats.small">
            <img
              :data-src="api_url + image.formats.small.url"
              alt=""
              class="lazy"
            >
            <figcaption>{{ image.caption }}</figcaption>
          </figure>
        </div>
      </div>
    </div>

    <BaseBtnBack />
  </section>
</template>

<script>
import MarkedParser from '../components/MarkedParser'
import BaseBtnBack from '../components/base/BaseBtnBack'

import gql from 'graphql-tag'
import LazyLoad from 'vanilla-lazyload'

const getPost = gql `
  query Post ($slug: String!) {
    posts(limit: 1, where: { slug: $slug }) {
      id
      slug
      manual_post_date
      title_en
      title_no
      content_en
      content_no
      contentShort_no
      news_category {
          category_en
          category_no
      }
      photo_cover {
          formats
          caption
      }
      photo_gallery {
          formats
          caption
      }
      rel_exh {
          title
          subtitle
          title_main_artist
          slug
      }
    }
  }
`;

export default {
    apollo: {
        post: {
            query: getPost,
            variables() {
                return {
                    slug: this.$route.params.slug
                };
            },
            update: data => data.posts
        }
    },
    components: {
        MarkedParser,
        BaseBtnBack
    },
    data() {
        return {
            ogTitle: '',
            ogDesc: '',
            ogImage: '',
            post: {},
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    metaInfo() {
        return { 
            title: this.ogTitle,
            meta: [
                { property: 'og:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'og:title' },
                { property: 'og:description', content: this.ogDesc, vmid: 'og:description' },
                { property: 'og:image', content: this.api_url + this.ogImage, vmid: 'og:image' },
                { property: 'og:type', content: 'article', vmid: 'og:type' },
                { property: 'article:publisher', content: 'https://www.nitja.no/', vmid: 'article:publisher' },
                { property: 'twitter:card', content: this.api_url + this.ogImage, vmid: 'twitter:card' },
                { property: 'twitter:title', content: this.ogTitle + ' | Nitja senter for samtidskunst', vmid: 'twitter:title' },
                { property: 'twitter:description', content: this.ogDesc, vmid: 'twitter:description' },
            ]
        }
    },
    mounted() {
        this.postLazyLoad = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 100, 
        });
    },
    updated() {
        let postTitle = this.post[0].title_no;
        let postDescription = this.post[0].contentShort_no;
        let postCover = this.post[0].photo_cover.formats.medium.url;
        
        if (postTitle) {
            this.ogTitle = postTitle 
        }
        if (postDescription) {
            this.ogDesc = postDescription 
        }
        if (postCover) {
            this.ogImage = postCover 
        }
        this.postLazyLoad.update();
    }
};
</script>
