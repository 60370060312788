<template>
    <div id="collection" class="defaultcontent-grid">
        <div class="posts-grid">
            <div v-for="post in posts" :key="post.slug" class="post-block post-block--news">
                <router-link :to="/news/ + post.slug">
                    <div id="post-content">
                        <div id="post-thumbnail">
                            <img v-if="post.photo_cover" :data-src="api_url + post.photo_cover.formats.small.url" class="post-thumbnail--news lazy" :alt="post.alternativeText">
                            <div v-else class="post-thumbnail--empty" />
                        </div>
    
                        <div id="post-details">
                            <p v-if="post.manual_post_date" class="metatext">
                                {{ post.manual_post_date | formatDateFull }}
                            </p>
                            <!-- <p v-if="$i18n.locale === 'en'" class="metatext">{{ post.news_category.category_en }}</p>
                                <p v-else class="metatext">{{ post.news_category.category_no }}</p> -->
                            <p v-if="$i18n.locale === 'en'" class="title-default">
                                {{ post.title_en }}
                            </p>
                            <p v-else class="title-default">
                                {{ post.title_no }}
                            </p>
                        </div>
    
                        <div id="post-text">
                            <MarkedParser v-if="$i18n.locale === 'nb'" class="markdown" :marked-content="post.contentShort_no" />
                            <MarkedParser v-else class="markdown" :marked-content="post.contentShort_en" />
                        </div>
                    </div>
                </router-link>
  
              <!-- <div id="post-btn">
                          <router-link :to="'/news/' + post.slug" class="square-btn--default">{{ $t('buttons.readMore') }}</router-link>
                      </div> -->
            </div>
        </div>
    </div>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'

export default {
    components: {
        MarkedParser
    },
    props: {
        posts: {
          type: Array,
          default: () => ([])
        }
    },
    data: function() {
        return {
            api_url: process.env.VUE_APP_STRAPI_API_URL,
        };
    },

};
</script>
