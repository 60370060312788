<template>
  <section
    v-if="posts"
    id="post-overview"
    class="defaultcontent-grid--fullwidth"
  >
    <!-- <div class="btn-selector">
            <button @click="changeCat()">
                <span>Reset</span>
            </button>
        <button v-for="category in newsCategories" :key="category.id" @click="changeCat(category.category_en)" :class="{ active: targetCat === category.category_en }">
            <span v-if="$i18n.locale === 'en'">{{ category.category_en }}</span>
            <span v-else>{{ category.category_no }}</span>
        </button>
        </div> -->

    <div v-if="$apollo.loading">
      <div class="spinner">
        {{ $t('headers.loading') }}
      </div>
    </div>
    
    <PostsGrid
      v-if="posts && posts.length"
      :posts="posts"
    />
    
    <div id="loadmore">
      <button
        v-if="morePosts"
        class="square-btn--default"
        @click="loadMore"
      >
        {{ $t('buttons.loadMorePosts') }}
      </button>
    </div>
  </section>
</template>

<script>
import PostsGrid from '../components/posts/PostsGrid';
import gql from 'graphql-tag';
import LazyLoad from 'vanilla-lazyload'

const getPosts = gql `
  query Posts ($start: Int!, $limit: Int!, $target: [String!]) {
    posts(
        start: $start, 
        limit: $limit, 
        sort: "manual_post_date:desc", 
        where: { news_category: { category_en: $target }}
    ) 
    {
        id
        title_en
        title_no
        slug
        published_at
        manual_post_date
        news_category {
            category_no
            category_en
        }
        contentShort_no
        contentShort_en
        photo_cover {
            formats
            alternativeText
        }
    }
    }
`;
const getAllPosts = gql `
  query totalCount {
    totalCount: posts {
      id
    }
  }
`;
const getAllCategories = gql `
  query Categories {
    newsCategories {
      id
      category_no
      category_en
    }
  }
`;

export default {
    apollo: {
        posts: {
            query: getPosts,
            variables() {
                return {
                    limit: 9,
                    start: 0,
                    target: this.targetCat
                }
            },
        },
        totalCount: {
            query: getAllPosts,
            result({ data }) {
                this.totalCount = data.totalCount
            },
        },
        newsCategories: {
            query: getAllCategories,
            result({ data }) {
                this.newsCategories = data.newsCategories
            },
        },
    },
    components: {
        PostsGrid
    },
    data: function() {
        return {
            targetCat: this.targetCat,
            posts: {},
            totalCount: {},
            categories: {},
        };
    },
    metaInfo() {
        return { 
            title: this.$t("nav.news"),
        }
    },
    computed: {
        morePosts() {
            return this.totalCount.length > this.posts.length
        },
    },
    mounted() {
        this.gridLazyload = new LazyLoad({
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            load_delay: 500,
        });
    },
    updated() {
        this.gridLazyload.update();
    },
    methods: {
        loadMore() {
            this.$apollo.queries.posts.fetchMore({
                variables: {
                    start: this.posts.length
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                        return previousResult
                    }
                    return Object.assign({}, previousResult, {
                        posts: [...previousResult.posts, ...fetchMoreResult.posts]
                    })
                }
            })

            // console.log('Total amount of posts in DB: ' + this.totalCount.length)
            // console.log('Amount of queried posts: ' + this.posts.length)
        },
        async changeCat(value) {
            this.targetCat = value
            this.skipQuery = false
            this.$apollo.queries.posts.refetch()
            // console.log('changeCat to: ' + this.targetCat)
            // console.log('Posts in category: ' + this.posts.length)
        }
    }

};
</script>
